import Link from 'next/link';

import { Typography } from 'components/Typography';

import { Button, List, Menu } from './styles';

interface Day {
  date: string;
  text: string;
}

interface IProps {
  days: Day[];
  dayToFetch: string;
}

function ChooseScheduleDayMenu({ days, dayToFetch }: IProps) {
  return (
    <Menu>
      <List>
        {days.map((day) => (
          <li key={day.date}>
            <Link href={`/tabla/${day.date}`}>
              <Button type="button" disabled={day.date === dayToFetch}>
                <Typography variant="body2">{day.text}</Typography>
              </Button>
            </Link>
          </li>
        ))}
      </List>
    </Menu>
  );
}

export { ChooseScheduleDayMenu };
