import styled from 'styled-components';

import { colors } from '@news/design-tokens/src/colors';
import { sizeUnits } from '@news/design-tokens/src/sizeUnits';

import { MaxWidthLayout } from 'components/common';
import { hideScrollbar } from 'styles/mixins/hideScrollbar';
import { theme } from 'styles/theme';

export const BroadcastList = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
  margin-top: ${sizeUnits[8]};
  width: 100%;
`;

export const Program = styled.li<{ $live: boolean }>`
  border: 1px solid ${colors.gray.light};
  border-width: 0 0 1px;
  font-weight: ${({ $live }) => ($live ? '700' : '500')};
  font-size: 14px;
`;

export const ProgramHeader = styled.header<{
  $live: boolean;
  $active: boolean;
  $alreadyBeenBroadcast: boolean;
}>`
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  background: ${({ $active, theme }) => ($active ? theme.colors.beige.dark : 'none')};
  box-sizing: border-box;
  padding: 5px ${sizeUnits[8]};
  min-height: 44px;
  color: ${({ $alreadyBeenBroadcast }) => ($alreadyBeenBroadcast ? colors.black[60] : colors.black[80])};

  h3,
  span {
    line-height: ${sizeUnits[16]};
  }

  h3 {
    font-size: inherit;
    font-weight: inherit;
    margin: 0;
  }

  span {
    margin-right: ${sizeUnits[16]};
  }
`;

export const ProgressBarContainer = styled.div`
  width: 30%;
  background: ${({ theme }) => theme.colors.red.darkTv4};
  height: ${sizeUnits[4]};
  margin-left: auto;
  position: relative;
  border-radius: 6px;
  overflow: hidden;

  &::before {
    content: '';
    background: ${colors.black[25]};
    width: 100%;
    height: 100%;
    position: absolute;
  }
`;

export const ProgressPercentage = styled.div<{ $percentage: number }>`
  position: absolute;
  background: ${colors.red.tv4};
  height: 100%;
  width: ${({ $percentage }) => ($percentage ? `${$percentage}%` : '0%')};
`;

export const ProgramMoreInfo = styled.div<{ $alreadyBeenBroadcast: boolean }>`
  padding: 0 ${sizeUnits[8]} ${sizeUnits[16]} ${sizeUnits[8]};

  p {
    color: ${({ $alreadyBeenBroadcast }) => ($alreadyBeenBroadcast ? colors.black[60] : colors.black[80])};
    font-size: inherit;
    font-weight: 400;
    margin: ${sizeUnits[8]} 0;
  }
`;

export const ShowAllDayButton = styled.button<{ $showAll: boolean }>`
  width: 100%;
  font-size: 14px;
  font-weight: 500;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${colors.black[80]};

  svg {
    margin-left: ${sizeUnits[16]};
    transform: ${({ $showAll }) => ($showAll ? 'rotate(90deg)' : 'rotate(-90deg)')};
  }
`;

export const ChannelMenu = styled.ul`
  margin: 0;
  padding: ${sizeUnits[16]};
  width: 100%;
  background: ${({ theme }) => theme.colors.beige.light};
  display: flex;
  flex-direction: row;
  align-items: center;
  list-style-type: none;
  -webkit-overflow-scrolling: touch;
  overflow: scroll hidden;
  scroll-snap-type: x mandatory;

  ${hideScrollbar()}

  ${({ theme }) => theme.mq.tablet} {
    display: none;
  }
`;

export const Link = styled.a`
  height: 58px;
  width: 58px;
  margin-right: ${sizeUnits[16]};
  background: white;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    height: auto;
    width: 80%;
  }
`;

export const Menu = styled.div`
  background: ${({ theme }) => theme.colors.beige.dark};
  width: 100%;
  align-items: flex-start;

  --webkit-overflow-scrolling: touch;

  overflow: scroll hidden;
  scroll-snap-type: x mandatory;
  ${hideScrollbar()}

  ${({ theme }) => theme.mq.tablet} {
    align-items: center;
    padding: 0;
    height: 60px;
    box-shadow: none;
  }
`;

export const List = styled.ul`
  ${MaxWidthLayout(false)}
  display: flex;
  margin: 0;
  flex-direction: row;
  align-items: center;
  list-style: none;
  justify-content: flex-start;
  padding: 0 ${sizeUnits[16]};
  height: 48px;
  ${({ theme }) => theme.mq.tablet} {
    height: 60px;
  }
`;

export const Button = styled.button`
  color: ${colors.black[100]};
  margin-right: ${sizeUnits[16]};
  text-transform: capitalize;

  ${theme.mq.tablet} {
    background: white;
    height: 43px;
    padding: 0 12px;
    border-radius: ${sizeUnits[8]};
  }

  &:disabled {
    color: ${colors.red.tv4};
    ${theme.mq.tablet} {
      background-color: ${theme.colors.pink.pale};
      cursor: not-allowed;
    }
  }
`;
